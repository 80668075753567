<template>
  <div role="group">
    <div class="caption">
      {{ title }}
      <span v-b-tooltip.hover :title="tooltip" clickable>
        <b-icon-question-circle></b-icon-question-circle>
      </span>
    </div>
    <b-input-group>
      <b-form-input
          :ref="'input-'+direction"
          data-lpignore="true"
          :value="tokenAmount.isZero() ? '': tokenAmount.valueOf()"
          placeholder="0"
          v-on:input="estimateAmount"
      ></b-form-input>
      <b-input-group-append>
        <div class="input-group-append-token" :id="direction">
          <b-button variant="white" :disabled="chooseTokenDisabled">
            <TokenIcon :token="tokenName"></TokenIcon>
          </b-button>
        </div>
      </b-input-group-append>
    </b-input-group>

    <b-popover :target="direction"
               :ref="direction"
               tabindex="0"
               triggers="click blur"
               placement="bottom"
               custom-class="select-options">
      <b-row>
        <b-col>
          <b-input-group class="my-3">
            <b-input-group-prepend>
              <b-button variant="white">
                <b-icon-search></b-icon-search>
              </b-button>
            </b-input-group-prepend>
            <b-form-input data-lpignore="true" placeholder="Search name"
                          :value="filter"
                          v-on:input="filterTokens"
                          trim
            ></b-form-input>
          </b-input-group>
        </b-col>
      </b-row>
      <b-row align-h="end">
        <b-col md="auto">
          <b-button size="min" variant="gray" class="my-2" v-on:click="showZeroBalances = !showZeroBalances">
            {{ showZeroBalances ? 'Hide zero balances' : 'Show zero balances' }}
          </b-button>
        </b-col>
      </b-row>

      <div class="options">
        <b-row class="caption py-2">
          <b-col>Token</b-col>
          <b-col class="text-right">You balance</b-col>
        </b-row>
        <b-row class="option" v-on:click="chooseToken(baseToken)" v-if="baseToken">
          <b-col>
            <IconCrypto :coinname="baseToken.symbol.toUpperCase()" color="color" format="svg"/>
            {{ baseToken.symbol.toUpperCase() }}
          </b-col>
          <b-col class="text-right">{{ mathRound(convertFromWei($store.state.web3.balance)) || 0 }}</b-col>
        </b-row>
        <b-row class="option"
               v-for="value in filteredTokens(filter)"
               v-bind:key="value.symbol"
               v-on:click="tokenName !== value.symbol ? chooseToken(value) : false"
               :disabled="tokenName === value.symbol"
        >
          <b-col>
            <IconCrypto :coinname=value.symbol color="color" format="svg"/>
            {{ value.symbol }}
          </b-col>
          <b-col class="text-right">
            <template v-if="getWalletBalance(value.symbol) === undefined">
              <b-spinner small label="Loading..."></b-spinner>
            </template>
            <template v-else>
              {{ getWalletBalance(value.symbol) }}
            </template>
          </b-col>
        </b-row>
      </div>

    </b-popover>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {GET_WALLET_BALANCES} from "@/store/modules/tokens.module";
import {mathRound} from "@/helpers/utils";
import BigNumber from "bignumber.js";

export default {
  name: "TokenInput",
  props: {
    title: String,
    tooltip: String,
    direction: String,
    tokenName: String,
    tokenAmount: BigNumber,
    chooseTokenDisabled: Boolean,
    isEstimated: Boolean
  },
  data: function () {
    return {
      baseToken: null,
      filter: '',
      showZeroBalances: true
    }
  },
  watch: {
    '$store.state.tokens.baseToken': {
      async handler (val) {
        if(Object.keys(val).length !== 0) {
          this.baseToken = this.getBaseToken
        }
      },
      immediate: true
    }
  },
  mounted() {
    // this.baseToken = this.getBaseToken
    this.$refs[this.direction].$on('show', async () => {
      this.filter = ''
      this.showZeroBalances = true
      // this.getWalletBalances()
    })
  },
  components: {
    TokenIcon: () => import("@/components/form/TokenIcon")
  },
  methods: {
    ...mapActions([GET_WALLET_BALANCES]),
    chooseToken(token) {
      let response = {
        direction: this.direction,
        token
      }
      this.$emit('chooseToken', response)
      this.$refs[this.direction].$emit('close')
    },
    estimateAmount(value) {
      const n = new BigNumber(value.replace(',', '.').replace(/[^\d.-]/g, ''))
      let returnValue = !isNaN(n.valueOf()) ? n : new BigNumber(0)
      // value = parseFloat(value.replace(',', '.').replace(/[^\d.-]/g, ''))
      this.$emit('estimateAmount', {direction: this.direction, amount: returnValue});
    },
    filteredTokens(str = '') {
      let tokens = this.$store.state.tokens.tokens
      if (str !== '') {
        tokens = Object.keys(tokens)
            .filter(key => key.toLowerCase().includes(str.toLowerCase()))
            .reduce((obj, key) => {
              return {...obj, [key]: tokens[key]}
            }, {})
      }
      if (!this.showZeroBalances) {
        tokens = Object.keys(tokens)
            .filter(key => this.getWalletBalance(key) > 0)
            .reduce((obj, key) => {
              return {...obj, [key]: tokens[key]}
            }, {})
      }
      return tokens
    },
    filterTokens(str) {
      this.filter = str.trim()
    },
    mathRound(number) {
      return mathRound(number, 8)
    },
  },
  computed: {
    ...mapGetters([
      'getWalletBalance',
      'convertFromWei',
      'getBaseToken'
    ])
  }
}
</script>

<style lang="scss">
  .row.option[disabled='disabled'] {
    opacity: 0.5;
  }

  .options {
    max-height: 450px;
    overflow: auto;
  }
</style>
